export function renderAnanSignature(department, firstName, lastName, emailAddress, phoneNumber, title, address) {
  return `
  <!DOCTYPE html>
<html>
<head>
    <title></title>
</head>
<body style="font-family: Helvetica, sans-serif;">
    <table style="text-align: left; border-collapse: collapse;">
        <thead>
            <th colspan="2" style="padding-bottom: 10px;">
                <p style="margin:0; text-align: left; font-size: 11pt;">${firstName} ${lastName}</p>
                <p style="margin:0; text-align: left; font-weight: 300; font-size: 11pt;">${title}</p>
            </th>
        </thead>
        <tbody>
            <tr>
                <td colspan="2" style="padding: 7px 40px 7px 0;">
                    <table>
                        <tr>
                            <td>
                                <img src="https://secureservercdn.net/160.153.138.53/2kl.8c5.myftpupload.com/wp-content/uploads/2022/03/anan-logotype-2.png" width=100>
                            </td>
                        </tr>
                        <tr>
                            <td style="font-size: 8pt; padding-top: 5px;">
                                Anan Data Solutions<br>
                                SKY Tower, HaMasger 35<br>
                                Tel Aviv-Yafo - 6721407, Israel  
                            </td>
                        </tr>
                    </table>
                </td>
                <td style="padding: 7px 40px 7px 0; ">
                    <table>
                        <tr>
                            <td style="font-size: 10pt; padding: 0 5px 5px 0;" height=20>
                                Mail:
                            </td>
                            <td style="font-size: 10pt; padding-bottom: 5px;">
                                <a href="mailto:${emailAddress}">${emailAddress}</a>
                            </td>
                        </tr>
                        <tr>
                            <td style="font-size: 10pt; padding: 0 5px 5px 0;" >
                                Tel.:
                            </td>
                            <td style="font-size: 10pt; padding-bottom: 5px;">
                                <a href="tel:${phoneNumber}">${phoneNumber}</a>
                            </td>
                        </tr>
                        <tr>
                            <td style="font-size: 10pt; padding: 0 5px 5px 0;">
                                Web:
                            </td>
                            <td style="font-size: 10pt; padding-bottom: 5px;">
                                <a href="https://anandata.io" target="_blank">https://anandata.io</a>      
                            </td>
                        </tr>
                    </table>
                </td>
            </tr>
            <tr>
                <td colspan="3" style="font-size: 8pt; width: 600px; padding-top: 10px;">
                    This e-mail message is for the sole use of the intended recipient(s) and may contain confidential and privileged information. Any unauthorised review, disclosure or distribution is prohibited. If you are not the intended recipient, please contact the sender by reply e-mail, delete the e-mail and destroy all copies of the original message.
                </td>
            </tr>
        </tbody>
    </table>
    <div style="font-size: 8pt;">
    </div>
</body>
</html>
  `
}
