export function renderCowaSignature(department, firstName, lastName, emailAddress, phoneNumber, title, address) {
  return `
  <!DOCTYPE html>
  <html>
  <head>
      <title></title>
  </head>
  <body style="font-family: Helvetica, sans-serif;">
      <table style="text-align: left; border-collapse: collapse;">
          <thead>
              <th colspan="2" style="padding-bottom: 10px; border-bottom: 1px solid black;">
                  <p style="margin:0; text-align: left; font-size: 11pt;">${firstName} ${lastName}</p>
                  <p style="margin:0; text-align: left; font-weight: 300; font-size: 11pt;">${title}</p>
              </th>
              <th style="border-bottom: 1px solid black;text-align:right;">
                  <a href="https://www.linkedin.com/company/cowamining/" target="_blank"><img src="https://cowa.ai/wp-content/uploads/2021/09/li.png" width=20></a>
                  <a href="https://twitter.com/CowaMining" target="_blank"><img src="https://cowa.ai/wp-content/uploads/2021/09/tw.png" width=20></a>
              </th>
          </thead>
          <tbody>
              <tr>
                  <td colspan="2" style="padding: 7px 40px 7px 0; border-bottom: 1px solid black;">
                      <img src="https://cowa.ai/wp-content/uploads/2021/09/logo.png" width=200>
                  </td>
                  <td style="padding: 7px 40px 7px 0; border-bottom: 1px solid black;">
                      <table>
                          <tr>
                              <td style="padding: 0 5px 5px 0;" height=20>
                                  <img src="https://cowa.ai/wp-content/uploads/2021/09/mail.png" height=14>
                              </td>
                              <td style="font-size: 10pt; padding-bottom: 5px;">
                                  <a href="mailto:${emailAddress}">${emailAddress}</a>
                              </td>
                          </tr>
                          <tr>
                              <td style="padding: 0 5px 5px 0;" >
                                  <img src="https://cowa.ai/wp-content/uploads/2021/09/phone.png" height=14>
                              </td>
                              <td style="font-size: 10pt; padding-bottom: 5px;">
                                  <a href="tel:${phoneNumber}">${phoneNumber}</a>
                              </td>
                          </tr>
                          <tr>
                              <td style="padding: 0 5px 5px 0;">
                                  <img src="https://cowa.ai/wp-content/uploads/2021/09/web.png" height=14>
                              </td>
                              <td style="font-size: 10pt; padding-bottom: 5px;">
                                  <a href="https://cowa.ai" target="_blank">https://cowa.ai</a>      
                              </td>
                          </tr>
                      </table>
                  </td>
              </tr>
          </tbody>
      </table>
  </body>
  </html>
  `
}
