import { renderCowaSignature } from './CowaSignature';
import { renderAnanSignature } from './AnanSignature';
import { renderLianSignature } from './LianSignature';
import { renderCheezeSignature } from './CheezeSignature';
import './App.css';

export default function App() {
  function handleSubmit(e) {
    e.preventDefault();
    let newState = {
      'organization': e.target['organization'].value,
      'department': e.target['department'].value,
      'firstName': e.target['first-name'].value,
      'lastName': e.target['last-name'].value,
      'emailAddress': e.target['email-address'].value,
      'phoneNumber': e.target['phone-number'].value,
      'title': e.target['title'].value,
      'address': e.target['address'].value
    }

    let renderFunction;
    switch (newState.organization) {
      case 'Lian':
        renderFunction = renderLianSignature;
        break;
      case 'Cowa':
        renderFunction = renderCowaSignature;
        break;
      case 'Anan':
        renderFunction = renderAnanSignature;
        break;
      case 'Cheeze':
        renderFunction = renderCheezeSignature;
        break;
      default:
        renderFunction = renderLianSignature;
    };

    const element = document.createElement("a");
    const file = new Blob([renderFunction(
      newState.department,
      newState.firstName, 
      newState.lastName, 
      newState.emailAddress, 
      newState.phoneNumber,
      newState.title,
      newState.address)], 
      {type: 'application/octet-stream'});

    element.href = URL.createObjectURL(file);
    console.log(element.href);
    element.download = "signature.html";
    document.body.appendChild(element); // Required for this to work in FireFox
    element.click();
  }

  return (
    <>
      <div className="max-w-lg mx-auto mt-5 px-12">
        <h1 className="p-3 font-bold text-md">Email signature generator</h1>
        <div className="md:grid md:grid-cols-2 md:gap-6">
          <div className="mt-5 md:mt-0 md:col-span-2">
            <form onSubmit={handleSubmit}>
              <div className="shadow overflow-hidden sm:rounded-md">
                <div className="px-4 py-5 bg-white sm:p-6">
                  <div className="grid grid-cols-6 gap-6">
                    <div className="col-span-6">
                      <label htmlFor="organization" className="block text-sm font-medium text-gray-700">
                        Organization
                      </label>
                      <select
                        id="organization"
                        name="organization"
                        autoComplete="organization"
                        className="mt-1 block w-full py-2 px-3 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                      >
                        <option>Lian</option>
                        <option>Cowa</option>
                        <option>Anan</option>
                        <option>Cheeze</option>
                      </select>
                    </div>

                    <div className="col-span-6">
                      <label htmlFor="department" className="block text-sm font-medium text-gray-700">
                        Department
                      </label>
                      <input
                        type="text"
                        name="department"
                        id="department"
                        className="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                      />
                    </div>

                    <div className="col-span-6 sm:col-span-3">
                      <label htmlFor="first-name" className="block text-sm font-medium text-gray-700">
                        First Name
                      </label>
                      <input
                        type="text"
                        name="first-name"
                        id="first-name"
                        autoComplete="given-name"
                        className="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                      />
                    </div>

                    <div className="col-span-6 sm:col-span-3">
                      <label htmlFor="last-name" className="block text-sm font-medium text-gray-700">
                        Last name
                      </label>
                      <input
                        type="text"
                        name="last-name"
                        id="last-name"
                        autoComplete="family-name"
                        className="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                      />
                    </div>

                    <div className="col-span-6">
                      <label htmlFor="email-address" className="block text-sm font-medium text-gray-700">
                        Email address
                      </label>
                      <input
                        type="email"
                        name="email-address"
                        id="email-address"
                        autoComplete="email"
                        className="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                      />
                    </div>

                    <div className="col-span-6">
                      <label htmlFor="phone-number" className="block text-sm font-medium text-gray-700">
                        Phone number
                      </label>
                      <input
                        type="tel"
                        name="phone-number"
                        id="phone-number"
                        autoComplete="tel"
                        className="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                      />
                    </div>

                    <div className="col-span-6">
                      <label htmlFor="title" className="block text-sm font-medium text-gray-700">
                        Title
                      </label>
                      <input
                        type="text"
                        name="title"
                        id="title"
                        className="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                      />
                    </div>

                    <div className="col-span-6">
                      <label htmlFor="title" className="block text-sm font-medium text-gray-700">
                        Address
                      </label>
                      <textarea
                        name="address"
                        id="address"
                        className="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                      />
                    </div>

                  </div>
                </div>
                <div className="px-4 py-3 bg-gray-50 text-right sm:px-6">
                  <button
                    type="submit"
                    className="inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                  >
                    Generate
                  </button>
                </div>
              </div>
            </form>
            <div className="p-3 text-xs">
              Fill the form above and generate the .html signature file. You can then open it in any web browser and copy paste the whole content to your mail application in the signature editor.
            </div>
          </div>
        </div>
      </div>
    </>
  )
}
