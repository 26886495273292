
//https://liangroup.kinsta.cloud/wp-content/uploads/2022/11/output-onlinegiftools.gif

export function renderCheezeSignature(department, firstName, lastName, emailAddress, phoneNumber, title, address) {
  return `
  <html>


<body>

<table style="text-align: left; border-collapse: collapse;">
 <tr style='height:16.85pt'>
  <td width=217 colspan=4 valign=top style='width:200pt;padding:0cm 0cm 0cm 0cm;
  height:16.85pt'>
  <p class=MsoNormal><b><span lang=EN-US style='font-family:"Arial",sans-serif;
  color:#5C297E'>${firstName} ${lastName}</span></b></p>
  </td>
  <td width=151 colspan=3 rowspan=3 valign=top style='width:4.0cm;padding:0cm 0cm 0cm 0cm;
  height:16.85pt'>
  <p class=MsoNormal align=center style='text-align:center'><span lang=EN-US
  style='font-family:"Arial",sans-serif'><img width=80
  src="https://raw.githubusercontent.com/liangroupdev/images-hosting/main/signatures/cheeze/logo.gif"></span><span style='font-family:Times'><u1:p></u1:p></span></p>
  </td>
  <td width=0 style='width:.3pt;padding:0cm 0cm 0cm 0cm;height:16.85pt'></td>
  <td></td>
 </tr>
 <tr style='height:8.55pt'>
  <td width=217 colspan=4 valign=top style='width:200pt;padding:0cm 0cm 0cm 0cm;
  height:8.55pt'>
  <p class=MsoNormal><span lang=EN-US style='font-size:9.0pt;font-family:"Arial",sans-serif;
  color:#404040'>${title}</span></p>
  </td>
  <td width=0 rowspan=2 style='width:.3pt;padding:0cm 0cm 0cm 0cm;height:8.55pt'></td>
  <td></td>
 </tr>
 <tr style='height:11.6pt'>
  <td width=217 colspan=4 valign=top style='width:200pt;padding:0cm 0cm 0cm 0cm;
  height:11.6pt'>
  <p class=MsoNormal><span lang=EN-US style='font-size:9.0pt;font-family:"Arial",sans-serif;font-style:italic;
  color:#404040'>${department}</span></p>
  </td>
  <td></td>
 </tr>
 <tr style='height:3.7pt'>
  <td width=217 colspan=4 valign=top style='width:200pt;padding:0cm 0cm 0cm 0cm;
  height:3.7pt'>
  <p class=MsoNormal><span lang=EN-US style='font-size:9.0pt;font-family:"Arial",sans-serif;
  color:#404040'>&nbsp;</span></p>
  </td>
  <td colspan=3 style='padding:0cm 0cm 0cm 0cm;height:3.7pt'>
  <p class=MsoNormal><span style='font-family:Times'>&nbsp;</span></p>
  </td>
  <td width=0 style='width:.3pt;padding:0cm 0cm 0cm 0cm;height:3.7pt'>
  <p class=MsoNormal><span style='font-family:Times'>&nbsp;</span></p>
  </td>
  <td></td>
 </tr>
 <tr style='height:14.65pt'>
  <td width=19 valign=top style='width:14.2pt;padding:0cm 0cm 0cm 0cm;
  height:14.65pt'>
  <p class=MsoNormal><b><span lang=EN-US style='font-size:7.5pt;font-family:
  "Arial",sans-serif'>P.<u1:p></u1:p></span></b></p>
  </td>
  <td width=9 valign=top style='width:7.1pt;padding:0cm 0cm 0cm 0cm;height:
  14.65pt'>
  <p class=MsoNormal><span lang=EN-US style='font-size:9.0pt;font-family:"Arial",sans-serif'><u1:p>&nbsp;</u1:p></span></p>
  </td>
  <td width=150 valign=top style='width:92.1pt;padding:0cm 0cm 0cm 0cm;
  height:14.65pt'>
  <p class=MsoNormal><span lang=EN-US style='font-size:9.0pt;font-family:"Arial",sans-serif;
  color:#404040'>${phoneNumber}</span></p>
  </td>
  <td width=66 rowspan=3 valign=top style='width:49.65pt;padding:0cm 0cm 0cm 0cm;
  height:14.65pt'></td>
  <td width=50 rowspan=4 valign=top style='width:37.8pt;padding:0cm 0cm 0cm 0cm;
  height:14.65pt'>
  <p class=MsoNormal align=right style='text-align:right'><a
  href="https://hellocheeze.ch/"><span style='font-family:"Arial",sans-serif;
  color:windowtext;text-decoration:none'><img border=0 height=24
  id="Image 3" src="https://raw.githubusercontent.com/liangroupdev/images-hosting/main/signatures/cheeze/web.png"
  alt="Une image contenant texte, transport, roue, fenêtre&#10;&#10;Description générée automatiquement"></span></a></p>
  </td>
  <td width=50 rowspan=4 valign=top style='width:37.8pt;padding:0cm 0cm 0cm 0cm;
  height:14.65pt'>
  <p class=MsoNormal align=center style='text-align:center'><a
  href="https://www.linkedin.com/company/hellocheeze"><span style='font-family:
  "Arial",sans-serif;color:windowtext;text-decoration:none'><img border=0
  height=24 id="Image 2" src="https://raw.githubusercontent.com/liangroupdev/images-hosting/main/signatures/cheeze/linkedin.png"
  alt="Une image contenant texte, clipart&#10;&#10;Description générée automatiquement"></span></a></p>
  </td>
  <td width=50 rowspan=4 valign=top style='width:37.8pt;padding:0cm 0cm 0cm 0cm;
  height:14.65pt'>
  <p class=MsoNormal><a href="https://www.instagram.com/hellocheeze.ch/"><span
  style='font-family:"Arial",sans-serif;color:windowtext;text-decoration:none'><img
  border=0 height=24 id="Image 1" src="https://raw.githubusercontent.com/liangroupdev/images-hosting/main/signatures/cheeze/instagram.png"
  alt="Une image contenant clipart&#10;&#10;Description générée automatiquement"></span></a></p>
  </td>
  <td width=0 style='width:.3pt;padding:0cm 0cm 0cm 0cm;height:14.65pt'></td>
  <td></td>
 </tr>
 <tr style='height:3.7pt'>
  <td width=19 rowspan=2 valign=top style='width:14.2pt;padding:0cm 0cm 0cm 0cm;
  height:3.7pt'>
  <p class=MsoNormal><b><span lang=EN-US style='font-size:7.5pt;font-family:
  "Arial",sans-serif'>E.<u1:p></u1:p></span></b></p>
  </td>
  <td width=9 rowspan=2 valign=top style='width:7.1pt;padding:0cm 0cm 0cm 0cm;
  height:3.7pt'>
  <p class=MsoNormal><span lang=EN-US style='font-size:9.0pt;font-family:"Arial",sans-serif'><u1:p>&nbsp;</u1:p></span></p>
  </td>
  <td width=150 rowspan=2 valign=top style='width:92.1pt;padding:0cm 0cm 0cm 0cm;
  height:3.7pt'>
  <p class=MsoNormal><span lang=EN-US style='font-size:9.0pt;font-family:"Arial",sans-serif;
  color:#404040'><a href="mailto:${emailAddress}"><span style='color:#404040;
  text-decoration:none'>${emailAddress}</span></a> </span></p>
  </td>
  <td width=0 style='width:.3pt;padding:0cm 0cm 0cm 0cm;height:3.7pt'></td>
  <td></td>
 </tr>
 <tr style='height:11.5pt'>
  <td width=0 style='width:.3pt;padding:0cm 0cm 0cm 0cm;height:11.5pt'></td>
  <td></td>
 </tr>
 <tr style='height:2.6pt'>
  <td width=19 valign=top style='width:14.2pt;padding:0cm 0cm 0cm 0cm;
  height:2.6pt'>
  <p class=MsoNormal><b><span style='font-size:7.5pt;font-family:"Arial",sans-serif'>A.<u1:p></u1:p></span></b></p>
  </td>
  <td width=9 valign=top style='width:7.1pt;padding:0cm 0cm 0cm 0cm;height:
  2.6pt'>
  <p class=MsoNormal><span lang=EN-US style='font-size:9.0pt;font-family:"Arial",sans-serif'><u1:p>&nbsp;</u1:p></span></p>
  </td>
  <td width=150 valign=top style='width:92.1pt;padding:0cm 0cm 0cm 0cm;
  height:2.6pt'>
  <p class=MsoNormal><span style='font-size:9.0pt;font-family:"Arial",sans-serif;color:#404040'>
  ${ address === '' ? '12 Rue Ami-Lullin<br>1207 Genève': address.replace(/\r?\n/g, '<br />') }
  </span></p>
  </td>
  <td width=66 valign=top style='width:49.65pt;padding:0cm 0cm 0cm 0cm;
  height:2.6pt'>
  <p class=MsoNormal><span style='font-size:10.0pt;font-family:"Arial",sans-serif'><u1:p>&nbsp;</u1:p></span></p>
  </td>
  <td width=0 style='width:140pt;padding:0cm 0cm 0cm 0cm;height:2.6pt'></td>
  <td></td>
 </tr>
 <tr style='height:24.65pt'>
  <td width=369 colspan=8 valign=top style='width:440pt;padding:0cm 0cm 0cm 0cm;
  height:24.65pt'>
  <p class=MsoNormal><span lang=EN-US style='font-family:Times'>&nbsp;</span></p>
  <p class=MsoNormal><span lang=EN-US style='font-size:7.5pt;font-family:"Arial",sans-serif;
  color:#AFABAB'>IMPORTANT: This message is confidential, for the exclusive use of the intended recipient and may contain privileged information. If you are not the intended recipient, retention, dissemination, distribution, copying or otherwise making use of this message is strictly prohibited. Please be kind enough to notify the sender immediately by e-mail if you have received this message by mistake and delete it from your system. Thank you. -- E-mails may be intercepted, altered, or read by unauthorized persons. If you send us messages by e-mail, we take this as your authorization to correspond with you by e-mail.</span><span lang=EN-US style='font-family:Times'><u1:p></u1:p></span></p>
  </td>
  <td></td>
 </tr>
</table>
</body>
</html>
  `
  
}